import { CompanyNameIcon, FooterContainer } from "./ContactsFooterComponents";


const ContactsFooter = () => {
    return (
        <FooterContainer>
            <CompanyNameIcon />
        </FooterContainer>
    )
};

export default ContactsFooter;
