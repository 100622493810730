import { useTranslation } from "react-i18next";
import {
    AddressInfoSection, AddressSectionWrapper, AddressText, StyledLink
} from "./ContactsAddressComponents";
import Map from "./Map/Map";
import { contactsEmail, contactsPhone, displayedContactsPhone } from "../../../../constants/contacts";

const AddressSection = () => {
    const { t } = useTranslation("translation", {keyPrefix: "contacts"});

    return (
        <AddressSectionWrapper>
            <AddressInfoSection>
                <StyledLink href={`tel:${contactsPhone}`}>
                    {displayedContactsPhone}
                </StyledLink>
                <StyledLink href={`mailto:${contactsEmail}`}>
                    {contactsEmail}
                </StyledLink>
                <AddressText>
                    {t("address")}
                </AddressText>
                <AddressText>
                    &nbsp;
                    {/*Here should be linkedin link*/}
                </AddressText>
            </AddressInfoSection>
            <Map />
        </AddressSectionWrapper>
    )
}

export default AddressSection;