import { FormAndAddressSectionWrapper } from "./ContactsPageComponents";
import ContactsFormSection from "./contactsForm/ContactsFormSection";
import AddressSection from "./contactsLocation/AddressSection";
import { useEffect } from "react";
import { useFooter } from "../../context/footerContext/useFooter";
import ContactsFooter from "./footer/ContactsFooter";

const ContactsPage = () => {

    const { setShowFooter } = useFooter();

    useEffect(() => {
        setShowFooter(false);
        return () => setShowFooter(true);
    }, [setShowFooter]);

    return (
        <>
            <FormAndAddressSectionWrapper>
                <ContactsFormSection />
                <AddressSection />
            </FormAndAddressSectionWrapper>
            <ContactsFooter />
        </>
    )
}

export default ContactsPage;