import React, { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import {
    FileInputLabel,
    FileSectionWrapper,
    PaperClipIcon,
    RemoveButton, SelectedFilesOptionsSection,
    SelectedFilesOptions,
    StyledFileInput,
    TipText
} from "../../pages/mainPage/feedbackFormSection/FeedbackFormSectionComponents";
import { ParagraphL } from "../text/ParagraphComponents";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashIcon } from "../../../resources/icon/trash.svg";
import { FeedbackType, FieldName } from "../../pages/mainPage/feedbackFormSection/FeedbackFormSection";
import { FlexContainer } from "../СommonComponents";
import { InputErrorWrapper } from "./InputComponents";
import { clearErrorsByTimeout } from "../../../utils/displayErrorsHelper";
import { MEGABYTE_IN_KILOBYTES } from "../../../constants/measures";

const _env = window.config || process.env;

interface FileInputProps extends React.HTMLAttributes<HTMLInputElement> {
    fieldName: FieldName;
    resetServerErrors?: () => void;
}

function isFileArray(source: unknown): source is File[] {
    return Array.isArray(source) && source.every(item => item instanceof File);
}

function getFileName(file: File) {
    const sizeInKB = file.size / MEGABYTE_IN_KILOBYTES;
    const size = sizeInKB > MEGABYTE_IN_KILOBYTES ? `${(sizeInKB / MEGABYTE_IN_KILOBYTES).toFixed(2)} MB` : `${sizeInKB.toFixed(2)} KB`;
    return `${file.name} (${size})`;
}

const FileInput = ({ fieldName, resetServerErrors, ...props }: FileInputProps) => {
    const { t } = useTranslation("translation", { keyPrefix: "main.feedbackForm" });
    const {
        register,
        setValue,
        getValues,
        setError,
        clearErrors,
        trigger,
        formState: { errors }
    } = useFormContext<FeedbackType>();

    const { onChange, ...otherProps } = register(fieldName);

    const selectedFiles = getValues(fieldName);
    const selectedFilesList = isFileArray(selectedFiles) ? selectedFiles : [];
    const fieldErrorsList = errors?.[fieldName]?.message?.split("\n");

    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const currentSelectedFileList: File[] = Array.from(event.target.files);

            let totalSize = selectedFilesList.reduce((acc: number, file: File) => acc + file.size, 0);

            const newErrors: string[] = [];
            const validFiles: File[] = [];

            for (const file of currentSelectedFileList) {
                if (file.size > Number(_env.MAX_SINGLE_FILE_SIZE)) {
                    newErrors.push(`${file.name} ${t("validation.fileSizeWarning")}`);
                } else if (totalSize + file.size > Number(_env.MAX_TOTAL_FILE_SIZE)) {
                    newErrors.push(t("validation.totalLimitations"));
                    break;
                } else {
                    validFiles.push(file);
                    totalSize += file.size;
                }
            }

            if (newErrors.length > 0) {
                setError(fieldName, {
                    type: "manual", message: newErrors.join("\n"),
                });
                clearErrorsByTimeout(fieldName, clearErrors, trigger);
                event.target.value = "";
                return;
            }

            clearErrors(fieldName);

            const updatedFileList = [...selectedFilesList, ...validFiles];
            setValue(fieldName, updatedFileList);
            onChange({ target: { value: updatedFileList } });
            resetServerErrors?.();
            event.target.value = "";
        }
    };

    const removeFile = (index: number) => {
        const currentFiles = getValues(fieldName);

        if (isFileArray(currentFiles)) {
            const updatedFiles = currentFiles.filter((_, currentIndex) => currentIndex !== index);
            setValue(fieldName, updatedFiles, { shouldValidate: true, shouldDirty: true });
            onChange({ target: { value: updatedFiles} });
        }
    };

    return (
        <FlexContainer $flexDirection="column">
            <FileSectionWrapper>
                <FileInputLabel htmlFor={props.id}>
                    <PaperClipIcon />
                    <ParagraphL>{t("files.add")}</ParagraphL>
                </FileInputLabel>
                <TipText>{t("validation.singleFileLimitations")}</TipText>

                <SelectedFilesOptionsSection>
                    {selectedFilesList.map((file, index) => (
                        <SelectedFilesOptions key={index}>
                            {getFileName(file)}
                            <RemoveButton type="button" onClick={() => removeFile(index)}>
                                <TrashIcon />
                            </RemoveButton>
                        </SelectedFilesOptions>
                    ))}
                </SelectedFilesOptionsSection>

                <StyledFileInput
                    id={props.id}
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    {...otherProps}
                />
            </FileSectionWrapper>
            {fieldErrorsList && fieldErrorsList.map((error, index) => (
                <InputErrorWrapper key={index}>{error}</InputErrorWrapper>
            ))}
        </FlexContainer>
    );
};

export default FileInput;
