import { FeedbackType } from "../components/pages/mainPage/feedbackFormSection/FeedbackFormSection";
import { MessagePayload } from "../types/messagePayload";
import { ContactsFormType } from "../components/pages/contactsPage/contactsForm/ContactsFormSection";

export const formFeedbackMessage = (data: FeedbackType | ContactsFormType): MessagePayload => {
    return {
        message: [
            data.name ? `Candidate name:\n ${data.name}` : null,
            data.email ? `Email:\n ${data.email}` : null,
            "position" in data && data.position ? `Desired position:\n ${data.position}` : null,
            "about" in data && data.about ? `About him/her:\n ${data.about}` : null,
            "message" in data && data.message ? `Message:\n ${data.message}` : null,
        ].filter(Boolean).join("\n\n"),
        replyTo: data.email,
        subject: "GoFarSoft feedback",
    };
};
