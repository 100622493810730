import { ReactNode, useState } from "react";
import FooterContext from "./FooterContext";

export const FooterProvider = ({ children }: { children: ReactNode }) => {
    const [showFooter, setShowFooter] = useState(true);

    return (
        <FooterContext.Provider value={{ showFooter, setShowFooter }}>
            {children}
        </FooterContext.Provider>
    );
};