import styled from "styled-components";
import { ParagraphL } from "../text/ParagraphComponents";

export const FormTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24rem;

  margin-bottom: 96rem;

  ${({ theme }) => `
    @media ${theme.media.medium} {
      margin-bottom: 64px;
    }
  `};
`;

export const DescriptionBlock = styled(ParagraphL)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral_400};
  max-width: 905rem;

  ${({ theme }) => `
    @media ${theme.media.medium} {
      font-size: 18px;
    }
  `};
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;
