import * as yup from "yup";
import { RegExpressions } from "../../../../constants/regex";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_LENGTH } from "../../../../constants/validationRules";
import { useTranslation } from "react-i18next";

export function feedbackValidation() {
    const { t } = useTranslation("translation", { keyPrefix: "validation"});

    return yup
    .object({
        name: yup.string().max(MAX_FIELD_LENGTH, t(`maxLength.${MAX_FIELD_LENGTH}`))
        .required(t("required")),
        phone: yup.string().required(t("required"))
        .matches(RegExpressions.phoneWithSpaces, t("phone")),
        email: yup.string().required(t("required"))
        .matches(RegExpressions.emailWithSpaces, t("email"))
        .max(MAX_FIELD_LENGTH, t(`maxLength.${MAX_FIELD_LENGTH}`)),
        position: yup.string().max(MAX_FIELD_LENGTH, t(`maxLength.${MAX_FIELD_LENGTH}`))
        .required(t("required")),
        about: yup.string().max(MAX_DESCRIPTION_FILED_LENGTH, t(`maxLength.${MAX_DESCRIPTION_FILED_LENGTH}`))
        .required(t("required")),
    });
}
