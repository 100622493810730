import { FeedbackType, FieldName } from "../components/pages/mainPage/feedbackFormSection/FeedbackFormSection";
import { FieldPath } from "react-hook-form";

type ClearErrorsFunction = (name?: (FieldPath<FeedbackType> | FieldPath<FeedbackType>[] | `root.${string}` | "root")) => void

type TriggerFunction = (name?: keyof FeedbackType | (keyof FeedbackType)[]) => Promise<boolean>;

const RESET_ERROR_TIMEOUT = 4000;

export function clearErrorsByTimeout (fieldName: FieldName, clearErrors: ClearErrorsFunction, trigger: TriggerFunction) {
    setTimeout(() => {
        clearErrors(fieldName);
        trigger(fieldName);
    }, RESET_ERROR_TIMEOUT);
}