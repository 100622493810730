import { TileLayer, Marker, MapContainer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import leaflet from "leaflet";

import locationIcon from "../../../../../resources/icon/location.svg";
import { MapWrapper } from "../ContactsAddressComponents";

const DefaultIcon = leaflet.icon({
    iconUrl: locationIcon,
    iconSize: [18, 22],
});

leaflet.Marker.prototype.options.icon = DefaultIcon;

const bounds: leaflet.LatLngBoundsExpression = [
    [53.880065, 27.486104], // bottom left corner
    [53.890010, 27.515768], // top right corner
];

const Map = () => {
    return (
        <MapWrapper>
            <MapContainer
                style={{ width: "100%", height: "80%", borderRadius: "16px" }}
                center={[53.885, 27.505]}
                zoom={14}
                maxBounds={bounds}
                maxBoundsViscosity={1.0}
                minZoom={14}
            >
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    attribution='&copy; OpenStreetMap contributors &copy; CARTO'
                />
                <Marker position={[53.88500280820254, 27.50468577919721]} />
            </MapContainer>
        </MapWrapper>
    );
};
export default Map;