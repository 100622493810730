import { ChangeEvent, InputHTMLAttributes } from "react";
import { FieldError, useFormContext, Path, FieldValues } from "react-hook-form";
import { FlexContainer } from "../СommonComponents";
import { InputField, InputErrorWrapper, InputWrapper } from "./InputComponents";

interface TextInputProps<FormType extends FieldValues, FieldNameType extends Path<FormType>> extends InputHTMLAttributes<HTMLInputElement> {
    fieldName: FieldNameType;
    title: string;
    placeholder: string;
    resetServerErrors?: () => void;
    fieldError?: FieldError;
}

const TextInput = <FormType extends FieldValues, FieldNameType extends Path<FormType>>({
   title,
   placeholder,
   fieldName,
   fieldError,
   resetServerErrors,
}: TextInputProps<FormType, FieldNameType>) => {
    const { register, clearErrors } = useFormContext<FormType>();

    const { onChange, ...otherProps } = register(fieldName);

    const fieldErrorMessage = fieldError?.message;
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event);
        clearErrors(fieldName);
        resetServerErrors?.();
    };

    return (
        <FlexContainer $flexDirection="column">
            <InputWrapper>
                {title}
                <InputField
                    type="text"
                    placeholder={placeholder}
                    {...otherProps}
                    $isError={!!fieldErrorMessage}
                    onChange={handleChange}
                />
            </InputWrapper>
            {fieldErrorMessage && <InputErrorWrapper>{fieldErrorMessage}</InputErrorWrapper>}
        </FlexContainer>
    );
};

export default TextInput;
